import {
	DocumentTypeProps,
	PrefixMappingProp,
	Document,
	FilterOptionsType,
	SelectedFilterProp,
	FilterOptionProp,
	FilterTagOptionProp,
} from "@/client/lib/interface"
import { GenericFilterProp } from "@/client/lib/interface"
import { AnnouncementTypeMap, FilterMenuOption, ItemProp } from "@/client/constants/constants"

function formatDate(publishedDate: string): string {
	const dateObj: Date = new Date(publishedDate)
	const year = dateObj.getFullYear() % 100
	const month = new Date(publishedDate).toLocaleString("en-US", {
		month: "short",
	})
	const day = dateObj.getDate()
	const res = day + " " + month + " '" + year
	return res
}

function formatDocumentInfo(
	docType: string,
	year: number,
	quarter?: string
): string {
	let shortForm: string
	switch (docType.toLowerCase()) {
		case "earnings-transcript":
			shortForm = "EC"
			break
		case "investor-presentation":
			shortForm = "IP"
			break
		case "announcement":
			shortForm = "AN"
			break
		case "annual-report":
			shortForm = "AR"
			break
		case "8-k":
			shortForm = "8-K"
			break
		case "10-k":
			shortForm = "10-K"
			break
		default:
			throw new Error(`Invalid document type: ${docType}`)
	}
	let formattedString = `${shortForm}-${year}`
	if (quarter) {
		formattedString += ` ${quarter.toUpperCase()}`
	}

	return formattedString
}

export function formatDocuments(
	documents: Document[],
	prefixMapping: PrefixMappingProp
) {
	return documents.map(document => ({
		...document,
		formatted_compact_title: `${prefixMapping[document.type]}-${document.year}${document.quarter ? " " + document.quarter.toUpperCase() : ""
			}`,
	}))
}

export function getPrefixMappingFromConfig(
	document_types: DocumentTypeProps[]
): PrefixMappingProp {
	if (!document_types) {
		return {} as PrefixMappingProp
	}
	return document_types.reduce<PrefixMappingProp>((result, item) => {
		result[item.filter_key] = item.short_form
		return result
	}, {} as PrefixMappingProp)
}

const serialize = (filters: GenericFilterProp) => {
	const queryParams: Record<string, string> = {}
	Object.keys(filters).map(key => {
		if (Array.isArray(filters[key]) && (filters[key] as string[])?.length) {
			const queryValue = (filters[key] as string[])?.join(",")
			if (queryValue) queryParams[key] = queryValue
		} else if (
			typeof filters[key] === "string" ||
			typeof filters[key] === "number"
		) {
			queryParams[key] = filters[key] as string
		} else if (filters[key] instanceof Date) {
			const date = filters[key] as Date
			const year = date.getFullYear()
			const month = String(date.getMonth() + 1).padStart(2, "0")
			const day = String(date.getDate()).padStart(2, "0")

			const formattedDate = `${year}-${month}-${day}`
			queryParams[key] = formattedDate
		}
	})

	return new URLSearchParams(queryParams).toString()
}

// Usage:
//   input: removeEmptyKeys({a: null, b: 'Hello', c: 3, d: undefined, e: [], f: [1, 2], g: ["a", "b"]})
//   output: {b: 'Hello', f: Array(2), g: Array(2)}
// eslint-disable-next-line
const removeEmptyKeys = (obj: any) => {
	return Object.fromEntries(
		Object.entries(obj).filter(keyValueArray => {
			if (Array.isArray(keyValueArray[1])) {
				return keyValueArray[1].length
			} else {
				return !(keyValueArray[1] == null || keyValueArray[1] == undefined)
			}
		})
	)
}

const getSearchParams = (
	searchParams: URLSearchParams,
	SearchParamsSchema: any // eslint-disable-line
) => {
	const result = SearchParamsSchema.safeParse(Object.fromEntries(searchParams))
	return result.success ? result.data : {}
}

const stringToArray = (params: string): string[] => params.split(",")

function getCompanies(
	data: {
		company_name: string
		company_id: string
	}[]
) {
	const company_names = []
	for (const company of data) {
		company_names.push(company.company_name) // Get the first key (sub-object key)
	}
	return company_names
}

export default function getAnnouncementTypes(data: AnnouncementTypeMap): ItemProp[] {
	const announcementTypes = []
	for (const key in data) {
		announcementTypes.push({ id: key, name: Object.keys(data[key])[0] })
	}
	return announcementTypes
}

const getYears = (startingYear: number): string[] => {
	const date = new Date()
	const year = (date.getFullYear() + 1).toString()
	const yearRange = []
	while (startingYear.toString() <= year) {
		yearRange.push(startingYear.toString())
		startingYear += 1
	}
	return yearRange

}
function addFilterKey(arr: FilterMenuOption[], options: FilterOptionsType[]): FilterMenuOption[] {
	return arr.reduce((result: FilterMenuOption[], item: FilterMenuOption) => {
		const filterOption = options.find(option => option.key === item.key)
		if (filterOption) {
			result.push({ ...item, filter_key: filterOption.filter_key })
		}
		return result
	}, [])
}

function showReset(options: FilterOptionProp[] | FilterTagOptionProp[], selectedFilters: SelectedFilterProp): boolean {
	const optionKey = options.map(item => (item.filter_key))
	const filterKeys = Object.keys(selectedFilters)
	return optionKey.some(element => filterKeys.includes(element))

}

function getAllYears(minYear: number): string[] {
	const maxYear = new Date().getFullYear() + 1
	const years: string[] = []
	for (let year = maxYear; year >= minYear; year--) {
		years.push(year.toString())
	}
	return years
}

export {
	showReset,
	getYears,
	addFilterKey,
	formatDate,
	formatDocumentInfo,
	getSearchParams,
	removeEmptyKeys,
	serialize,
	stringToArray,
	getCompanies,
	getAllYears
}
